






































import Vue from "vue";
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

import AuthLayout from "@/components/auth/Layout.vue";
import AuthLink from "@/components/auth/Link.vue";

export default Vue.extend({
  components: {
    AuthLayout,
    AuthLink,
  },
  name: "Login",
  data: () => {
    return {
      email: "",
      password: "",
      processing: false,
    };
  },
  methods: {
    ...mapActions(["setUser", "login", "showLoading", "hideLoading"]),
    async signIn() {
      if (this.processing) return;

      await (this as any).$recaptchaLoaded();

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const token = await (this as any).$recaptcha();

      try {
        this.processing = true;

        await this.login({
          email: this.email,
          password: this.password,
          gRecaptchaResponse: token,
        });

        this.processing = false;
        this.hideLoading();

        this.$router.push({
          name: "e-Membership Card",
        });
      } catch (err) {
        this.processing = false;
      }
    },
    navigateToForgetPassword() {
      this.$router.push({
        name: "ForgetPassword",
      });
    },
    getErrors(name: string, model: any): Array<string> {
      const errors = new Array<string>();
      if (!model.$dirty) return errors;

      switch (name) {
        case "password":
          !model.required && errors.push("Password is required.");
          break;
        case "email":
          !model.email && errors.push("Must be valid email");
          !model.required && errors.push("Email is required");
          break;
        default:
          break;
      }
      return errors;
    },
  },
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  watch: {
    processing: function (val) {
      val ? this.showLoading() : this.hideLoading();
    },
  },
});
